import React from 'react';
// import { RxOpenInNewWindow } from 'react-icons/rx';

const About = () => {
  return (
    <section className="pt-[100px] pb-[100px] bg-shadow" id="intro">
      <div className="inner bg-white shadow-md delay-75 rounded-lg p-9 relative">
            <h2>About Rachel</h2>
            <div>
              <p className="text-lg pb-4">
                <span className="accent-line">I'm a software engineer with a passion for creating excellence.</span>
              </p>
              <p className="text-md pb-2">
                Since 2018, I've been full-time developing for a variety of industries and realms of projects. I began in web development, focusing on high quality front-end experiences that were SEO friendly, 
                accessible, and UX driven.
              </p>
              <p className="text-md pb-2">
                Soon after, I migrated to working full-stack, thriving in building out complex systems and engineering creative solutions
                for solving challenging problems.
              </p>
              <p className="text-md pb-2">
                Feel free to reach out if you'd like to connect, or meet up and have a coffee chat!
              </p>
            </div>
            {/* <a 
                href=""
                target="_blank"
                rel="noreferrer"
                className="no-underline absolute mt-10 bottom-0 right-[50%] translate-x-[50%] translate-y-[150%] bg-white rounded-md transition-shadow shadow-lg hover:shadow-sm px-7 py-4 cursor-pointer z-[10]"
              >
                <h4 className="pb-0 whitespace-nowrap">Open Resume <RxOpenInNewWindow className="inline align-middle" /></h4>
            </a> */}
      </div>
    </section>
  );
};

export default About;
